<template>
  <div>
    <div :id="uuid"
      ref="layerrow"
      class="bg-gray-800 border-gray-700 timeline-content-row"
      :style="rowStyle"
      :class="{ 'disabled' : !layer.enabled, 'bg-blue-800' : isDragTarget }"
      @dragenter.prevent.stop="onDragEnter"
      @dragover.prevent.stop="onDragOver"
      @dragleave.prevent.stop="onDragLeave"
      @ondrop="onDrop"
      @drop.prevent.stop="onDrop"
    >
      <template>
        <template v-if="isEmpty">
          <div
            class="w-32 p-1 text-center btn btn-outline btn-2xs"
            @click.prevent="onClick"
          >
            + Module
          </div>
        </template>
        <template v-else>
          <template v-if="layer.type === 'attribute'">
            <template v-if="layer.keyframes">
              <TimelineLayerKeyframe
                v-for="(keyframe, index) in layer.keyframes"
                :key="index"
                :keyframe="keyframe"
                :scale="scale"
                @view-module="$emit('view-module', layer.id)"
                @mouse-down="$emit('mouse-down', $event)"
              />
            </template>
          </template>
          <template v-else>
            <TimelineModule
              v-for="module in layerModules"
              :key="module.id"
              :module="module"
              :scale="scale"
              :overallduration="overallduration"
              :layer="layer"
              :show-debug="showDebug"
              :active-module-id="activeModuleId"
              @view-module="$emit('view-module', $event)"
              @mouse-down="$emit('mouse-down', $event)"
              @drag-move-module-end="$emit('drag-move-module-end', $event)"
              @drag-move-module-start="$emit('drag-move-module-start', $event)"
            />
          </template>
        </template>
      </template>
    </div>
    <template v-if="layer.children">
      <div
        v-if="layer.expanded"
        class="outline-none"
        :style="rowStyle"
      >
        <TimelineLayerRow
          v-for="child in layer.children"
          :key="child.id"
          :scale="scale"
          :layer="child"
          @view-module="$emit('view-module', $event)"
          @mouse-down="$emit('mouse-down', $event)"
        />
      </div>
    </template>
  </div>
</template>
<script>
// eslint-disable-next-line
import iframeCommunication from '@mixins/iframe-communication-mixin.js'
const TimelineLayerRow = () => import('./TimelineLayerRow')
const TimelineModule = () => import('../modules/TimelineModule')

export default {
  mixins: [iframeCommunication],

  components: {
    TimelineLayerRow,
    TimelineModule
  },

  props: ['scale', 'layer', 'modules', 'type', 'overallduration', 'showDebug', 'activeModuleId'],

  data () {
    return {
      isDragTarget: false,
      uuid: null
    }
  },

  mounted() {
    this.uuid = this.uuidv4()
    this.registerIframeDragListeners(this.uuid)
  },

  computed: {

    layerModules: {
      get: function () {
        return this.modules.filter(row => row.layer.id === this.layer.id)
      },
      set: function (values) {

        // this.layer.modules = values
        // console.log('Set on computed!', values)
      }
    },

    isEmpty () {
      return this.layer.is_empty === true
    },

    fullwidthPx () {
      return this.overallduration * this.scale
    },

    rowStyle () {
      return 'width: ' + this.fullwidthPx + 'px'
    }

  },

  methods: {
    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        )
    },

    onDragEnter (event) {
      this.isDragTarget = true
    },

    onDragLeave (event) {
      this.isDragTarget = false
    },

    onDragOver (event) {
      this.isDragTarget = true
    },

    onDrop (event) {
      this.isDragTarget = false

      let moduleId = event.dataTransfer.getData('application/x-previz.timeline-module')
      let assetId = event.dataTransfer.getData('application/x-previz.asset')

      if (moduleId !== null && moduleId !== '') {
        this.handleModuleMove(moduleId, event)
      } else if (assetId !== null && assetId !== '') {
        this.handleAssetDrop(assetId, event)
      } else {
        console.log('Drag event is not module or asset drop. might be native file??')
        this.handleFileDrop(event.dataTransfer.items, event)
      }
    },

    handleFileDrop (items, event) {
      const handle = (file, path) => {
        if (file.name.startsWith('.')) return

       // document.getElementById('drive').contentWindow.postMessage({event:'handleFileDrop', file:file},'*')
        // this.handleSceneDrop(file)
        this.handleFileToModuleDrop(file, event)
      }

      const traverse = (item, path) => {
        path = path || ''

        if (item.isFile) {
          item.file((file) => {
            handle(file, path)
          })
        }

        if (item.isDirectory) {
          let dirReader = item.createReader()
          dirReader.readEntries(function (entries) {
            Array.from(entries).forEach((child) => {
              traverse(child, path + item.name + '/')
            })
          })
        }
      }

      Array.from(items).forEach((item) => {
        item = item.webkitGetAsEntry()
        if (item) {
          traverse(item)
        }
      })
    },

    handleFileToModuleDrop (file, event) {
      let x = (event.offsetX) / this.scale
      this.$emit('new-module', { file: file, layerId: this.layer.id, x: x })
    },

    handleAssetDrop (asset, event) {
      let x = (event.offsetX) / this.scale
      this.$emit('new-module', { assetId: asset, layerId: this.layer.id, x: x })
    },

    handleModuleMove (moduleId, event) {
      // We need to figure out what the actual X movement has been
      // then we can scale it and use that to figure out the new start time
      let pointOffset = Number.parseInt(event.dataTransfer.getData('application/x-previz.timeline-module-touchoffset'))

      // We use the raw 'clientX' - the horizonal position of the cursor on the screen
      // Then get the left hand edge position of the row itself (using the $refs.layerrow dom element)
      // Then we can cleanly get the actual offset of the drop position from the left most edge of the row
      let clientX = event.clientX
      let rowEl = this.$refs.layerrow
      let rowElBox = rowEl.getBoundingClientRect()
      let rowX = rowElBox.left

      let offset = clientX - rowX

      let x = (offset - pointOffset) / this.scale

      if (x < 0) {
        x = 0
      }
      this.$emit('drag-move-module', { moduleId: moduleId, layerId: this.layer.id, x: x })
    }

    // onModuleDragStart (event) {

    //   console.log('Move start', event)

    //   var itemRect = event.item.getBoundingClientRect();
    //   this.dragOffset = event.originalEvent.clientX - parseInt(itemRect.left)

    //   // leftOff = event.originalEvent.clientX - parseInt(itemRect.left);
    //   // topOff = event.originalEvent.clientY - parseInt(itemRect.top);
    // },

    // onModuleDragEnd (event) {
    //   let clientX = event.originalEvent.clientX
    //   let l = clientX - this.dragOffset

    //   console.log('Move to end on ', event.newIndex)
    //   console.log('OFFSET X: ', event.originalEvent.offsetX)

    //   // Now scale this [l] value somehow to something we want

    //   // event.item.style.position = 'absolute';
    //   // event.item.style.top = event.originalEvent.clientY - topOff + 'px';
    //   // event.item.style.left = event.originalEvent.clientX - leftOff + 'px';

    // },

    // onModuleDragChange (event) {
    //   console.log('Change event', event)
    // },

    // onModuleDragMove(event, original) {
    //   console.log('Move event', event, original)

    //   return true
    // },

    // onMouseMove (event) {
    //   console.log('Mouse move direct', event)
    // }
  }

}
</script>
